import React, { Component } from 'react';
import { Snackbar, Table, TableBody, TableHead, TableRow, TableCell, } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import { withLocalize } from "react-localize-redux";
import { connect } from 'react-redux';
// import Paper from '@material-ui/core/Paper';
import Moment from 'react-moment';
import 'moment-timezone';
// localize date time

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    // minWidth: 700,
    width: '100%'
  },
  cell: {
    paddingRight: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
  },
  link: {
    color:'inherit'
  },
  copy: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

const catLabelLt = {
  "1": "vordere Ansicht mit Perspektive/Aufsicht #C1C1",
  "2": "linke Ansicht (von schräg links) mit Perspektive #C1L1",
  "3": "rechte Ansicht (von schräg rechts) mit Perspektive #C1R1",
  "4": "frontale Ansicht ohne Perspektive #C1N1",
  "5": "frontale Rückansicht ohne Perspektive #C7N1",
  "6": "frontale Rückansicht mit Perspektive #C7C1",
  "7": "frontale Seitenansicht rechts ohne Perspektive #C8N1",
  "8": "frontale Seitenansicht rechts mit Perspektive #C8C1",
  "9": "frontale Seitenansicht links ohne Perspektive #C2N1",
  "10": "frontale Seitenansicht links mit Perspektive #C2C1",
  "11": "untere Ansicht #C9N1",
  "12": "obere Ansicht #C3N1",
}

function s(str) {
  return str.replace(/[^a-zA-Z0-9 ]+/g, '').replace(/\s+/g, '-').toLowerCase();
}

class AssetTableComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  };

  handleCopyClick = () => {
    this.setState({ open: true });
    const baseUrl = "https://api.jde-mediabox.com/gs1/";
    navigator.clipboard.writeText(`${baseUrl}${this.gtin}_${this.imgCat}_s${this.version}_v${this.variant}-${s(this.props.asset.name)}.jpg`);
  };

  render() {
    const { asset, classes, translate, lang, user } = this.props;
    const customFields = asset.customFields || [];
    const val = id => {
      const field = customFields.find(cf => cf.fieldId === id);
      return (field && field.value) ? field.value : "";
    };
    this.gtin = val("100").padStart(14, '0');
    this.imgCat = (val("102") && catLabelLt[val("102")]) ? catLabelLt[val("102")].split('#')[1] : "";
    this.version = val("103").padStart(2, '0');
    this.variant = val("104").padStart(2, '0');

    return (
      <>
        <Table className={classes.table}>
          <TableHead>
              <TableRow>
                <TableCell className={classes.cell}>{translate("asset.metadata")}</TableCell>
                <TableCell className={classes.cell}></TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={asset.id}>
              <TableCell className={classes.cell}>{translate("asset.uploadDate")}</TableCell>
              <TableCell className={classes.cell}><Moment format="DD.MM.YYYY HH:ss" date={asset.creationDate} /></TableCell>
            </TableRow>
            { user.isSuperUser &&
              <TableRow key="0">
                <TableCell className={classes.cell}>{translate("asset.createdBy")}</TableCell>
                <TableCell className={classes.cell}><a href={`mailto:${asset.createdByUser.userName}?subject=${asset.name}%20(${asset.id})`} className={classes.link}>{`${asset.createdByUser.firstname} ${asset.createdByUser.lastname}`}</a></TableCell>
              </TableRow>
            } { user.isSuperUser &&
              <TableRow key="1">
                <TableCell className={classes.cell}>{translate("asset.lastModifiedBy")}</TableCell>
                <TableCell className={classes.cell}><a href={`mailto:${asset.lastModifiedByUser.userName}?subject=${asset.name}%20(${asset.id})`} className={classes.link}>{`${asset.lastModifiedByUser.firstname} ${asset.lastModifiedByUser.lastname}`}</a></TableCell>
              </TableRow>
            }
            { customFields.map(customField => (customField.fieldId === "104" || customField.fieldId === "103") ? null : (
              <TableRow key={customField.fieldId}>
                <TableCell className={classes.cell}>{customField.name[lang]}</TableCell>
                <TableCell className={classes.cell}>
                  {
                      (customField.fieldId === "101" || customField.fieldId === "100")
                    ?
                      <Link to={`/search/${customField.value}`} className={classes.link}>{customField.value}</Link>
                    :
                      customField.fieldId === "102"
                    ?
                    <span className={classes.copy} onClick={this.handleCopyClick}>{catLabelLt[customField.value]}</span>
                    :
                      customField.value
                  }
                  </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose} message={<span>{translate("asset.copied")}</span>} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    user: state.authentication.user,
    isLoading: (state.assetIsLoading || state.assetsIsLoading),
  };
};

const AssetTable = connect(mapStateToProps)(withLocalize(withStyles(styles)(AssetTableComponent)));

export { AssetTable }