import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
// import { cartGetOne } from '../../_actions/';
import Image from "react-graceful-image";
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DownloadIcon from '@material-ui/icons/GetApp';
// import DeleteIcon from '@material-ui/icons/Delete';

import { AddButton } from '../AddButton';
import { RemoveButton } from '../RemoveButton';
import { AssetName } from '../AssetName';
import { AssetDownloadDropdown } from '../AssetDownloadDropdown';
import { assetRequestRemove } from '../../_actions/asset.actions';

const px = 240;

const styles = {
  root: {
    width: '100%',
    height: 180
  },
  card: {
    maxWidth: px,
    minWidth: px,
    maxHeight: px,
    minHeight: px,
    
  },
  media: {
    // ⚠️ object-fit is not supported by IE11.
    // objectFit: 'contain',
    // maxWidth: 160,
    width: 'auto',
    // height: 160,
    // maxHeight: 160,
    margin: '0 auto'
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: (px-10),
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: 0,
  },
  content: {
    padding: 5,
    textAlign: 'center',
    bottom: 0
  },
  actions: {
    // padding: 5,
    display: 'flex',
  }
};

// const Title = ({title}) => {
//   return (<span>{title}</span>)
// }

class AssetCard extends Component {
  state = {
    anchorEl: null,
  };

  constructor(props){
    super(props);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
  }

  // constructor(props){
  //   super(props);
  // }

  componentDidMount(){
    // this.props.cartGetOne();
  }

  handleDownloadClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleRemoveClick(assetId) {
    this.props.assetRequestRemove(assetId);
    console.log({assetId});
  }

  handleDownloadClose = () => {
    this.setState({ anchorEl: null });
  };


  render() {
    const { classes, asset, cart, isLoading } = this.props;
    const isInCart = () => {
      for(let item of cart.items){
        if(item.id === asset.id)
          return true
      }
      return false;
    }


    return (
      <Card className={classes.card} style={{ border: isInCart() ? '1px solid rgba(0,0,0,.5)' : asset.removalRequested ? '1px solid rgba(255,0,0,.1)': '1px solid rgba(0,0,0,.1)' }}>
        <Link to={`/asset/${asset.id}`} className={classes.link}>      
          <CardActionArea className={classes.root}>
            <CardMedia
              component={ () =>
                <div className={classes.media}>
                  <Image
                    src={asset.defaultThumbTicket}
                    height="140"
                    alt={asset.name}
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      display: 'block',
                      position: 'absolute',
                      left: '50%',
                      top: 0,
                      transform: 'translateX(-50%)',
                    }}
                    retry={{ count: 15, delay: 1, accumulate: "add" }}
                  />
                </div>
              }
              src="/images/logo-footer.png"
            />
            <CardContent className={classes.content}>
              <Typography gutterBottom component="p" className={classes.title}>
                <AssetName>
                  {asset.name}
                </AssetName>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Link>
        <CardActions className={classes.actions} disableActionSpacing>
          {
            isInCart() ?
            <RemoveButton assetId={asset.id} disabled={isLoading} /> :
            <AddButton assetId={asset.id} disabled={isLoading} />
          }
          <IconButton aria-label="Download" size="small" onClick={this.handleDownloadClick} disabled={isLoading}>
            <DownloadIcon/>
          </IconButton>
          
          {/* 
          <IconButton aria-label="Löschen beantragen" size="small" onClick={() => this.handleRemoveClick(asset.id)} disabled={isLoading}>
            <DeleteIcon/>
          </IconButton>
          */}
          <AssetDownloadDropdown data={asset} handleDownloadClose={this.handleDownloadClose} anchorEl={this.state.anchorEl} disabled={isLoading}/>

        </CardActions>
      </Card>
    );
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     cartGetOne: (id) => dispatch(cartGetOne(id))
//   };
// };

const mapStateToProps = state => {
  return {
    cart: state.cart,
    isLoading: state.assetIsLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    assetRequestRemove: (assetId) => dispatch(assetRequestRemove(assetId))
  };
}

AssetCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

AssetCard = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AssetCard));

export { AssetCard }
